.table-cell {
  color: black;
}

.field-label {
  color: #4c4ca4;
}

.form-checkbox {
  padding: 22px 0px 0px 8px;
}

/* CSS Document */

#wrapper {
  padding-left: 0;
}

.side-nav {
  display: block;
  position: fixed;
  top: 50px;
  left: 210px;
  width: 210px;
  height: 100%;
  margin-left: -210px;
  border: none;
  transition: 0.5s;
  border-radius: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f6f7f9;
  bottom: 45px;
  padding-bottom: 40px;
  /* border-right: 1px solid #d3d4d8; */
  /* transition: ease-in-out 0.4s;
      -webkit-transition: ease-in-out 0.4s;
      -moz-transition: ease-in-out 0.4s;
      -ms-transition: ease-in-out 0.4s;
      -o-transition: ease-in-out 0.4s; */
}

.side-nav.m-close > li > p {
  width: 50px;
  padding: 10px 0;
  text-align: center;
}

// .side-nav.m-close .m-bottom .m-dots {
//   display: none;
// }

.side-nav:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  // width: 1px;
  background: lightgray;
  right: 0;
  border-right: 1px solid lightgray;
  height: 100%;
}

.webkit .side-nav {
  bottom: 0;
}

.safari .side-nav {
  bottom: 50px;
}

.side-nav > li.more-item {
  margin: 20px 0 0;
}

.side-nav > li > p {
  width: 210px;
  padding: 17px 0px 17px 20px;
  font-size: 16px;
  line-height: 16px;
  position: relative;
  vertical-align: middle;
  text-transform: capitalize;
  color: #000000 !important;
  text-decoration: none;
  display: flex;
  margin: 0;
  cursor: pointer;
}

.side-nav > li > p i {
  font-size: 20px;
  line-height: 20px;
  padding-right: 10px;
  color: #b0bf1c;
}

.side-nav > li > p span {
  vertical-align: middle;
}

.navbar-nav > li:hover {
  /* background: #B5D7EF; */
  background: rgb(255, 167, 167);
  // background: rgba(176, 191, 28, 0.3);
}

.navbar-nav > li.active {
  border-right: 1px solid white;
  z-index: 1;
  position: relative;
}

.page-contect {
  width: 100%;
  padding: 0;
}

.side-nav > li > ul {
  border-bottom: 1px #e9e9e9 solid;
  background: #f1f4f8;
  padding: 9px 0 0;
  margin: 0;
}

.side-nav > li > ul {
  text-align: right;
}

.subnav-content {
  display: none;
  position: absolute;
  left: 20%;
  top: 50px;
  bottom: 0;
  right: 0;
  background-color: lightgray;
  /* width: 80%; */
  z-index: 999;

  overflow: scroll;
  scrollbar-width: none;
}

.subnav.active .subnav-content {
  display: block;
}

.side-nav > li > ul > li {
  width: 95%;
  display: inline-block;
  text-align: left;
  padding: 3px 0;
}

.side-nav > li > ul li p {
  display: block;
  padding: 6px 0px 6px 15px;
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  /* color: #b1b5b8; */
  color: #565656;
}

.safari .side-nav > li > p svg {
  margin-right: 5px;
}

.side-nav > li {
  display: grid;
  border-right: 1px solid lightgray;
}

.side-nav > li > ul li > p:hover,
.side-nav > li > ul li > p:focus {
  color: #fff !important;
  background-color: #f3927f !important;
  border-radius: 15px 0 0 15px;
}

.side-nav li.active > p {
  color: black !important;
  background-color: white !important;
  // border-radius: 15px 0 0 15px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.subnav.active > .side-nav:after {
  border: 1px solid red !important;
}

.side-nav .m-arrow {
  color: #9d9d9d;
  font-size: 25px;
  line-height: 25px;
  float: right;
  margin-right: 15px;
}

.side-nav.m-close .m-arrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.side-nav .m-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px 0;
  background: #f6f7f9;
  width: 210px;
  transition: 0.5s;
  z-index: 99;
  border-right: 1px solid #d3d4d8;
  display: flex;
  justify-content: end;
  border-top: 1px solid #d3d4d8;
  /* transition: ease-in-out 0.4s;
	-webkit-transition: ease-in-out 0.4s;
	-moz-transition: ease-in-out 0.4s;
	-ms-transition: ease-in-out 0.4s;
	-o-transition: ease-in-out 0.4s; */
}

.side-nav.m-close .m-bottom {
  width: 50px;
  /* transition: ease-in-out 0.4s;
	-webkit-transition: ease-in-out 0.4s;
	-moz-transition: ease-in-out 0.4s;
	-ms-transition: ease-in-out 0.4s;
	-o-transition: ease-in-out 0.4s; */
}

.side-nav .m-arrow:hover {
  // .side-nav .m-dots:hover{
  color: #007acc;
}

.side-nav .m-arrow svg {
  transition: ease-in-out 0.4s;
  -webkit-transition: ease-in-out 0.4s;
  -moz-transition: ease-in-out 0.4s;
  -ms-transition: ease-in-out 0.4s;
  -o-transition: ease-in-out 0.4s;
}

.side-nav.m-close .m-arrow svg {
  transform: rotate(-180deg);
  transition: ease-in-out 0.4s;
  -webkit-transition: ease-in-out 0.4s;
  -moz-transition: ease-in-out 0.4s;
  -ms-transition: ease-in-out 0.4s;
  -o-transition: ease-in-out 0.4s;
}

.side-nav > li > p.collapsed:after {
  display: none;
}

.side-nav > li ul {
  margin: 0;
  padding: 5px 0 0 10px;
  list-style-type: none;
}

.page-wrapper {
  position: relative;
  padding: 5px 13px 0 13px;
  margin-top: 55px;
  margin-left: 210px;
  transition: 0.5s;
  min-height: calc(100vh - 55px);
}

.page-information {
  padding: 10px 0 0;
}

.form-group .form-control.error {
  border-color: #ff0000 !important;
}

.mt_10 {
  width: 10%;
}

.mt_45 {
  width: 45%;
}

.mt_18 {
  width: 18%;
}

.account-home {
  max-width: 1140px;
  min-width: 800px;

  ul.account-home-menu {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 0;

    li {
      padding: 10px;
      color: white;
      position: relative;
      font-weight: 600;
      cursor: pointer;
    }
    li::before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 0%;
      background: red;
      border-radius: 5px;
      transition: all 0.3s;
    }
    // li.active,
    // li:hover {
    //   color: red;
    // }
    li.active::before,
    li:hover::before {
      width: 100%;
    }
  }
}

span.search-icon {
  cursor: pointer;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-item:first-of-type,
.accordion-item:last-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.accordion-button:not(.collapsed) {
  background: #ffd8d8 !important;
}

.ant-drawer-content-wrapper {
  margin-top: 50px;
}

// .css-1konsb1-MuiModal-root-MuiDrawer-root
.MuiDrawer-paper {
  margin-top: 50px !important;
}

.driver-expense-dialog {
  .k-dialog {
    margin: 10px;
  }
}

@media (min-width: 240px) and (max-width: 568px) {
  // .page-wrapper {
  //   // margin-top: 160px;
  //   // margin-left: 0;
  // }

  .page-title h3 {
    font-size: 12px;
    line-height: 14px;
  }

  .page-wrapper .page-title-top .page-title-right > a {
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin: -8px 0 0;
  }

  .page-contect {
    margin-top: 0;
    position: fixed;
    left: 210px;
    right: 0;
    bottom: 0;
    background: #fff;
    overflow-y: scroll;
    top: 50px;
    padding: 20px 0 0;
    width: 60%;
    box-shadow: 12px 0 12px -12px #808080;
    -webkit-box-shadow: 12px 0 12px -12px #808080;
    -moz-box-shadow: 12px 0 12px -12px #808080;
    -ms-box-shadow: 12px 0 12px -12px #808080;
    -o-box-shadow: 12px 0 12px -12px #808080;
    z-index: 99;
  }

  .page-title .page-setting {
    padding-right: 10px;
  }
}

@media (min-width: 569px) and (max-width: 767px) {
  .navbar-right {
    float: right !important;
  }

  .side-nav > li > p i {
    font-size: 15px;
    line-height: 15px;
    padding-right: 10px;
  }

  .side-nav > li > p {
    font-size: 16px;
    line-height: 16px;
  }

  // .page-wrapper {
  //   margin-top: 125px;
  //   margin-left: 0;
  // }

  .page-contect {
    margin-top: 0;
    position: fixed;
    left: 210px;
    right: 0;
    bottom: 0;
    background: #fff;
    overflow-y: scroll;
    top: 50px;
    padding: 20px 0 0;
    width: 60%;
    box-shadow: 12px 0 12px -12px #808080;
    -webkit-box-shadow: 12px 0 12px -12px #808080;
    -moz-box-shadow: 12px 0 12px -12px #808080;
    -ms-box-shadow: 12px 0 12px -12px #808080;
    -o-box-shadow: 12px 0 12px -12px #808080;
    z-index: 99;
  }
}

@media (min-width: 768px) {
  .side-nav.m-close {
    width: 50px;
    margin-left: -50px;
    left: 50px;
  }

  .side-nav.m-close > li > p span {
    display: none;
  }

  .page-contect {
    position: fixed;
    top: 50px;
    left: 210px;
    transition: 0.5s;
    bottom: 0;
    width: 85%;
    background: #fff;
    height: 100%;
    padding: 12px 55px 20px 45px;
    box-shadow: 12px 0 12px -12px #808080;
    -webkit-box-shadow: 12px 0 12px -12px #808080;
    -moz-box-shadow: 12px 0 12px -12px #808080;
    -ms-box-shadow: 12px 0 12px -12px #808080;
    -o-box-shadow: 12px 0 12px -12px #808080;
    z-index: 99;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page-contect {
    padding: 20px;
  }

  .page-contect {
    width: 72%;
    padding: 12px 25px 20px 15px;
    overflow: scroll;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page-contect {
    width: 72%;
    padding: 12px 35px 20px 25px;
    overflow: scroll;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .page-contect {
    width: 80%;
    padding: 12px 35px 20px 25px;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .page-contect {
    width: 80%;
    padding: 12px 35px 20px 25px;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .page-contect {
    width: 80%;
  }
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .page-contect {
    width: 81%;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .page-contect {
    width: 83%;
  }
}

@media (min-width: 1700px) and (max-width: 1799px) {
  .page-contect {
    width: 83%;
  }
}


