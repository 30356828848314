@import "~@progress/kendo-theme-default/dist/all.scss";

:root {
  --kendo-color-primary-subtle: #ffe5e5; /* Light red with 20% opacity */
  --kendo-color-primary-subtle-hover: #ff9999; /* Light red with 40% opacity */
  --kendo-color-primary-subtle-active: #ff4d4d; /* Light red with 60% opacity */
  --kendo-color-primary: #ff0000; /* Main primary color (pure red) */
  --kendo-color-primary-hover: #cc0000; /* Darker red for hover */
  --kendo-color-primary-active: #990000; /* Even darker red for active state */
  --kendo-color-primary-emphasis: #ff6666; /* Lighter red for emphasis */
  --kendo-color-primary-on-subtle: #660000; /* Dark red for text on subtle background */
  --kendo-color-on-primary: #ffffff; /* White text for contrast on primary */
  --kendo-color-primary-on-surface: #ff0000; /* Surface primary color */
}
